/* eslint-disable */

const fullpagePromoBlock = document.querySelector('.fullpage__promo_block');

if (fullpagePromoBlock) {

  const roundDotsContainer = fullpagePromoBlock.querySelector('.rounds__container');
  const round1 = roundDotsContainer.querySelector('.round-1');
  const round2 = roundDotsContainer.querySelector('.round-2');

  function layerInit() {
      const diameterValue = (Math.sqrt(window.innerHeight ** 2 + window.innerWidth ** 2) * 2);

      const tl = anime.timeline({
          easing: 'easeInOutQuart'
      });

      anime.set([round1, round2], {
          scale: 0,
          height: diameterValue,
          width: diameterValue,
          top: -(diameterValue / 2),
          left: -(diameterValue / 2),
      });

      tl.add({
          targets: round1,
          scale: 1,
          duration: 1000
      }).add({
          targets: round2,
          scale: 1,
          duration: 1000,
          delay: 50,
          complete () {
              anime({
                  targets: fullpagePromoBlock,
                  opacity: 0,
                  duration: 1000,
                  delay: 300,
                  complete () {
                      fullpagePromoBlock.remove();
                  }
              })
          }
      });
  }
  window.addEventListener('load', () => {
    layerInit();
  });

  window.addEventListener("resize", () => {
    window.requestAnimationFrame(layerInit);
  });

};
